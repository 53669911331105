<template>
  <div>
    <div style="margin-top:16px">
      <div v-for="(v,index) in list" :key="index" class="patient-card" @click="goBack(v)">
        <div class="patient-card-boday">
          <div class="info-item">
            <div class="left">
              <span class="name">{{ v.patxm }}</span>
            </div>
            <div class="right">
              {{ v.gendermc == null ? "无" : v.gendermc }} {{ v.age == null ? "0" : v.age }}岁
            </div>
          </div>
          <div class="info-item">
            <div class="left">
              证件类型：
            </div>
            <div class="right">
              {{ v.zjlxmc == null ? "无" : v.zjlxmc }}
            </div>
          </div>

          <div class="info-item">
            <div class="left">
              证件号：
            </div>
            <div class="right">
              {{ v.zjh }}
            </div>
          </div>
          <div class="info-item">
            <div class="left">
              手机号：
            </div>
            <div class="right">
              {{ v.mobile }}
            </div>
          </div>

          <img class="img-arrow" :src="require('../../assets/img/doc_job.png')" mode>
          <img
            :data-id="v.id "
            class="edit"
            :src="require('../../assets/img/edit.png')"
            @click.stop="addPatient(v.id)"
          >
          <span v-show="v.isdefault==1" class="default">默认</span>
        </div>
      </div>
    </div>
    <div class="add" @click="addPatient(null)">
      <div>
        <img class="img-add" :src="require('../../assets/img/increase.png')" mode>
      </div>
      <div class="add_patient">
        添加患者
      </div>
    </div>
  </div>
</template>

<script>
import {setItem} from "@/utils/cache"
import {mapMutations} from 'vuex'
import * as types from '../../store/mutation-types'
export default {
  props: {
    list: {
      type: Array,
      default: () => ([])
    }
  },
  data() {
    return {
      staus: false,
      newList: {}
    }
  },
  mounted() {
    // console.log(this.$route.query.appoint, "请我")
    this.staus = this.$route.query.appoint
  },

  methods: {
    goBack(v) {
      // console.log(v, '企鹅')
      this.newList = v
      if (this.staus) {
        setItem('choosePatient', v)
        this.choosepatientList(v)
        this.$router.back()

        // this.$router.replace('/appointment')
      } else {
        return
      }
    },
    addPatient(id) {

      const isId = id ? id : -1
      if (this.list != null && this.list.length >= 6 && !id) {
        this.$toast('最多只能添加6位就诊人')
        return false
      }
      this.$router.push(`/patient/${isId}`)
      //this.$router.push({ path: '/patient', query: { isId} })
    },
    ...mapMutations({
      choosepatientList:`patient/${types.SET_CHOOSE_LIST}`
    })
  }
}
</script>

<style scoped lang='less'>
@import '../../assets/styles/variable.less';

div {
  box-sizing: border-box;
}

.patient-card {
  position: relative;
  width: 686px;
  height: 310px;
  margin-bottom: 40px;
  background: linear-gradient(159deg,
  rgba(107, 190, 249, 1) 0%,
  rgba(28, 156, 246, 1) 100%);
  border-radius: 20px;

  .patient-card-boday {
    padding: 32px 32px;
    height: 100%;

    .info-item {
      display: flex;
      align-items: center;
      padding: 12px;
      line-height: 40px;
      font-size: 30px;
      color: #3d3d3d;

      .left {
        width: 30%;
        color: #fff;
        font-size: 32px;
        .name{
          font-weight: bold;
        }
      }

      .right {
        width: 30%;
        color: #fff;
      }
    }

    .img-del {
      position: absolute;
      top: 32px;
      right: 32px;
      width: 40px;
      height: 40px;
    }

    .img-arrow {
      position: absolute;
      right: 0px;
      bottom: 0px;
      width: 224px;
      height: 221px;
    }
  }
}

.img-add {
  width: 40px;
  height: 40px;
}

.edit {
  position: absolute;
  top: 32px;
  right: 32px;
  font-size: 24px;
  color: #fff;
  width: 32px;
  height: 32px;
}

.default {
  position: absolute;
  top: 32px;
  right: 100px;
  font-size: 20px;
  color: rgba(28, 156, 246, 1);
  text-align: center;
  width: 80px;
  height: 32px;
  line-height: 32px;
  background: rgba(255, 255, 255, 1);
  border-radius: 17px;
}

.add {
  text-align: center;
  margin-top: 24px;
  border: 2px dashed rgba(151, 151, 151, 1);
  border-radius: 20px;
  padding: 24px 0px;

  .add_patient {
    font-size: @font-size-24;
    color: @light-font-color;
  }
}
</style>
