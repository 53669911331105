<!--
 * @Author: your name
 * @Date: 2021-03-04 17:05:11
 * @LastEditTime: 2021-03-09 10:44:16
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \BlueCrossInternetHospitalGZH\src\divs\patient\index.vue
-->
<template>
  <div class="patient">
    <div class="reminder">
      最多可添加6人
    </div>
    <patient-list :list="list" />
  </div>
</template>
<script>
 import { patientModel } from '@/api/patient'
 import PatientList from "@/components/patient/patientList"

export default {
  components: {PatientList},
  data(){
      return{
        list:[]
      }
    },
    mounted(){
      this.loadData()
    },
    methods:{
      loadData(){
        patientModel.getPatientList().then(res=>{
            this.list = res.data.patLists
        })
      }

    }
}
</script>
<style scoped lang='less'>
div {
    box-sizing: border-box;
}
.patient {
  padding: 0 32px 98px;

  .reminder {
    color: #1c9cf6;
    font-size: 26px;
    margin-top:32px;
  }

}
</style>
